import {
  Box, Input, Image
} from '@chakra-ui/react'
import { ACCEPTED_IMG_LIST } from 'utils/constants'
import { AddImageIcon } from 'assets/icons'
import { ImagesPng } from 'assets/images'
import { callImageSourceSelectionDialog } from 'utils/cordova_image_source'

export const ProfileAvatar = ({ inputFileRef, handleUploadFile, profilePicture }) => {
  const UploadClick = (event) => {
    /* eslint-disable no-undef */
    if (process.env.REACT_APP_NODE_ENV === 'CORDOVA_APPLICATION' && cordova?.platformId === 'android') {
      event.stopPropagation()
      callImageSourceSelectionDialog('pp')
    } else {
      inputFileRef.current.click()
    }
  }
  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <>
      <Box
        position="relative"
        top="-10px"
      >
        <Box position="absolute" top="6" right="-8px">
          <label
            style={{ cursor: 'pointer' }}
            name="add-profile-img"
            htmlFor="userImg"
          >
            <AddImageIcon />
          </label>
        </Box>

        <Image
          boxSize="65px"
          objectFit="cover"
          src={profilePicture?.imagePath || ImagesPng?.DefaultImage}
          alt="User Image"
          borderRadius="full"
          onClick={UploadClick}
        />

        {/* {profilePicture?.imagePath?.length > 0 && isHoverImage && (
      <Box
        onClick={removeUploadedFile}
        cursor="pointer"
        position="absolute"
        bottom="0"
        right="-8px"
      >
        <DeleteImageIcon />
      </Box>
    )} */}
      </Box>
      <Input
        display="none"
        ref={inputFileRef}
        id="userImg"
        type="file"
        onChange={handleUploadFile}
        accept={ACCEPTED_IMG_LIST}
      />
    </>
  )
}
