import { useEffect, useState } from 'react'
import { Box, Skeleton } from '@chakra-ui/react'
import { convertFromRaw, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'

const LazyImageBlock = ({ block, contentState }) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { src, alignment } = contentState.getEntity(block.getEntityAt(0)).getData()
  let imgAlignment = 'center'

  if (alignment === 'left') {
    imgAlignment = 'flex-start'
  }

  if (alignment === 'right') {
    imgAlignment = 'flex-end'
  }

  useEffect(() => {
    if (src.includes(';base64,')) {
      setImageLoaded(true)
    } else {
      setImageLoaded(false)
    }
  }, [src])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: imgAlignment
      }}
    >
      <Skeleton
        isLoaded={imageLoaded}
        fadeDuration={1}
      >
        <img
          src={src}
          alt="content"
          onLoad={() => setImageLoaded(true)}
          style={{
            width: imageLoaded ? 'auto' : '350px',
            height: imageLoaded ? 'auto' : '120px'
          }}
        />
      </Skeleton>
    </div>
  )
}

const blockRendererFn = (block) => {
  if (block.getType() === 'atomic') {
    return {
      component: LazyImageBlock,
      editable: false
    }
  }
  return null
}

export const TextEditorReader = ({ item }) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

  useEffect(() => {
    if (item.body) {
      const contentState = convertFromRaw(item.body)
      setEditorState(EditorState.createWithContent(contentState))
    }
  }, [item.body])

  return (
    <Box>
      <Editor
        editorState={editorState}
        blockRendererFn={blockRendererFn}
        readOnly
        toolbarHidden
      />
    </Box>
  )
}
