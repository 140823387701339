import {
  useEffect,
  useRef,
  useState
} from 'react'
import {
  MapContainer,
  Marker,
  TileLayer,
  useMapEvent
} from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import MarkerClusterGroup from '@changey/react-leaflet-markercluster'
import { ImagesPng } from 'assets/images'
import Leaflet from 'leaflet'
import {
  deleteProfileItem,
  getNotificationMessage,
  handleLocationMyPosition,
  markersSelectors,
  setMarkerUpdate,
  setSelectedMarkerData,
  showCurrentChatFriendMarkerCoordinate,
  usersSelectors,
  getMarkers
} from 'store'
import { chatCredentialsSelectors } from 'store/chat/selectors'
import {
  INITIAL_POSITION,
  LOCATION_TYPE,
  MAP_ATTRIBUTION,
  MAP_URL,
  MAX_ZOOM_LEVEL,
  REJECTED,
  UNSUPPORTED_MAX_ZOOM_LEVEL,
  ZOOM_LEVEL
} from 'utils/constants'
import {
  isLiteralNumber,
  flyLocation,
  iconMarkerImage,
  htmlTemplate,
  hideCounter,
  showCounter
} from 'utils/helpers'
import { v4 as uuid } from 'uuid'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  savetiles, tileLayerOffline, downloadTile, saveTile
} from 'leaflet.offline'

import { MarkerPopup } from './Markers/MarkerPopup'
import { Markers } from './Markers'
import { StyledMapContainer } from './styled'

import 'leaflet/dist/leaflet.css'

export const Map = ({
  setVisibleMarkersCount, setShowMarkerCount
}) => {
  const { xmppLiveLocation } = useSelector(chatCredentialsSelectors)
  const {
    isLocationMyPosition,
    markers,
    currentChatFriendMarkerCoordinate,
    markerUpdate,
    filters,
    selectedMarkerData
  } = useSelector(markersSelectors)
  const {
    user, activeProfile, selectedUserProfile, userProfile
  } = useSelector(usersSelectors)

  const [popupComponent, setPopupComponent] = useState('')
  const [leafletOfflineInitialized, setLeafletOfflineInitialized] = useState(false)
  const dispatch = useDispatch()
  const mapRef = useRef()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const lat = searchParams.get('lat')
  const lng = searchParams.get('lng')
  const zoom = searchParams.get('zoom')
  const liveLocCoord = [xmppLiveLocation?.lat, xmppLiveLocation?.lng]

  // eslint-disable-next-line max-len
  const locationType = activeProfile && activeProfile.location_type && Object.keys(activeProfile?.location_type)[0]
  const isLiveLocation = locationType === LOCATION_TYPE.LiveLocation

  const staticLocationPosition = activeProfile?.coordinates
  const isLocationValid = () => !Number.isNaN(xmppLiveLocation.lat)
    && !Number.isNaN(xmppLiveLocation.lng)

  useEffect(() => {
    if (user && isLocationMyPosition && isLiveLocation
    && isLocationValid() && selectedUserProfile?.id === activeProfile?.id) {
      flyLocation(mapRef, xmppLiveLocation.lat, xmppLiveLocation.lng, false, false)
      dispatch(handleLocationMyPosition())
    }
    if (activeProfile && activeProfile?.coordinates?.length > 0
      && selectedUserProfile?.id === activeProfile?.id) {
      flyLocation(mapRef, activeProfile.coordinates[0], activeProfile.coordinates[1])
    } else if (selectedUserProfile?.id !== activeProfile?.id
      && selectedUserProfile?.coordinates?.length > 0) {
      flyLocation(mapRef, selectedUserProfile.coordinates[0], selectedUserProfile.coordinates[1])
    }
  }, [isLocationMyPosition])

  useEffect(() => {
    if (currentChatFriendMarkerCoordinate) {
      const { coord, isStatic } = currentChatFriendMarkerCoordinate
      flyLocation(
        mapRef,
        coord[0],
        coord[1],
        true,
        isStatic
      )
      dispatch(showCurrentChatFriendMarkerCoordinate(null))
    }
  }, [currentChatFriendMarkerCoordinate?.coord])

  const handleVisibleMarkerCount = () => {
    let totalMarkers = markers
    let visibleZoom = mapRef.current?.getZoom()
    let isFiltered = localStorage.getItem('is-filtered')
    let ownMarkers = userProfile

    ownMarkers?.map((e) => {
      if (e?.coordinates) {
        totalMarkers = [...totalMarkers, e]
        return totalMarkers
      }
      return null
    })
    setTimeout(() => {
      if (mapRef.current) {
        if (visibleZoom === 4 && isFiltered === 'false') {
          setVisibleMarkersCount(totalMarkers)
        } else {
          let clusters = []

          mapRef.current.eachLayer((l) => {
            if (l instanceof Leaflet.Marker
                && mapRef.current.getBounds().contains(l.getLatLng())
                // eslint-disable-next-line no-underscore-dangle
                && l._group?.options.className !== 'marker-cluster-custom') {
              clusters.push(l)
              // eslint-disable-next-line no-underscore-dangle
            } else if (l._group?.options.className === 'marker-cluster-custom'
              && l.getChildCount?.() !== 'undefined'
              && mapRef.current.getBounds().contains(l.getLatLng())) {
              for (let i = 0; i < l.getChildCount?.(); i += 1) {
                clusters.push([])
              }
            }
          })
          setVisibleMarkersCount(clusters)
        }
      }
    }, 500)
  }

  const handleCoordinates = () => {
    const mapCenter = mapRef.current.getCenter()
    const mapZoom = mapRef.current.getZoom()

    if (!window.location.search.includes('?id')) {
      setSearchParams({
        lat: mapCenter.lat.toFixed(6),
        lng: mapCenter.lng.toFixed(6),
        zoom: mapZoom
      })
    }

    localStorage.setItem('lat', mapCenter.lat.toFixed(6))
    localStorage.setItem('lng', mapCenter.lng.toFixed(6))
    localStorage.setItem('zoom', mapZoom)
  }

  const handleMapMoveEnd = () => {
    const moveEndZoom = mapRef?.current.getZoom()
    localStorage.setItem('zoom', moveEndZoom)

    const openProfileAfterAnimation = localStorage.getItem('openProfileAfterAnimation')
    if (
      openProfileAfterAnimation !== null
      && openProfileAfterAnimation !== ''
      && moveEndZoom === MAX_ZOOM_LEVEL
    ) {
      navigate(`/?id=${openProfileAfterAnimation}`)
      localStorage.removeItem('openProfileAfterAnimation')
    } else if (
      localStorage.getItem('id') === null
      || localStorage.getItem('id') === ''
    ) {
      handleCoordinates()
      handleVisibleMarkerCount()
    }
  }

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.off('moveend', handleMapMoveEnd)
      mapRef.current.on('moveend', handleMapMoveEnd)
    }
  }, [markers])

  // const saveTilesOnMoveEnd = () => {
  //   if (
  //     initialRun
  //     && leafletOfflineInitialized
  //     && navigator.onLine
  //   ) {
  //     document.querySelector('#saveTiles').click()
  //   }
  // }

  useEffect(() => {
    if (markerUpdate || filters) {
      dispatch(setMarkerUpdate(false))
      handleVisibleMarkerCount()
    }
  }, [markerUpdate, filters])

  const handleInitialCenter = () => {
    const latLS = localStorage.getItem('lat')
    const lngLS = localStorage.getItem('lng')
    const zoomLS = localStorage.getItem('zoom')
    let centerCoordinates = staticLocationPosition ?? INITIAL_POSITION

    if (!window.location.search.includes('?id') && !window.location.search.includes('?lat')) {
      if (
        isLiteralNumber(latLS)
        && isLiteralNumber(lngLS)
        && isLiteralNumber(zoomLS)
      ) {
        setSearchParams({
          lat: latLS,
          lng: lngLS,
          zoom: zoomLS
        })
      }
    }

    if (!window.location.search.includes('?id') && mapRef?.current) {
      if (
        (
          lat !== null
          || lng !== null
          || zoom !== null
        )
        && (
          !isLiteralNumber(lat)
          || !isLiteralNumber(lng)
          || !isLiteralNumber(zoom)
        )
      ) {
        dispatch(
          getNotificationMessage({
            type: REJECTED,
            message: 'Invalid URL parameters',
            isEditProfile: true
          })
        )
      }

      if (
        isLiteralNumber(latLS)
        && isLiteralNumber(lngLS)
        && isLiteralNumber(zoomLS)
      ) {
        mapRef.current.setView([
          +latLS,
          +lngLS
        ], zoomLS)
      } else if (
        isLiteralNumber(lat)
        && isLiteralNumber(lng)
        && isLiteralNumber(zoom)
      ) {
        mapRef.current.setView([
          +lat,
          +lng
        ], zoom)
      } else {
        mapRef.current.setView(centerCoordinates, ZOOM_LEVEL)
      }
    }
  }

  const autoSaveTiles = () => {
    const mapRefCurrent = mapRef?.current

    if (
      mapRefCurrent
      && mapRefCurrent?.getZoom() >= 4
    ) {
      const tlOffline = tileLayerOffline(
        MAP_URL,
        {
          attribution: MAP_ATTRIBUTION,
          maxNativeZoom: UNSUPPORTED_MAX_ZOOM_LEVEL,
          maxZoom: UNSUPPORTED_MAX_ZOOM_LEVEL,
          minNativeZoom: 4,
          minZoom: 4
        }
      ).addTo(mapRefCurrent)

      tlOffline.on('tileload', (e) => {
        const { tile } = e
        const url = tile.src
        if (url.search('blob:') !== -1) {
          if (process.env.REACT_APP_MAP_DEBUG === 'true') {
            console.log(`Loaded ${url} from idb`)
          }
          return
        }
        const { x, y, z } = e.coords
        const { _url: urlTemplate } = e.target
        const tileInfo = {
          key: url,
          url,
          x,
          y,
          z,
          urlTemplate,
          createdAt: Date.now()
        }
        downloadTile(url)
          .then((dl) => saveTile(tileInfo, dl))
          .then(() => (process.env.REACT_APP_MAP_DEBUG === 'true' ? console.log(`Saved ${url} in idb`) : null))
      })

      // workaround
      let saveControl = savetiles(
        tlOffline,
        {
          alwaysDownload: true,
          confirm: (layer, successCallback) => {
            console.log('add')
            successCallback()
          },
          confirmRemoval: (layer, successCallback) => {
            console.log('remove')
            successCallback()
          },
          bounds: mapRefCurrent?.getBounds(),
          saveText: '<i id="saveTiles" />',
          rmText: '<i id="deleteTiles" />'
        }
      )

      if (tlOffline) {
        saveControl.addTo(mapRefCurrent)
        document.querySelector('.savetiles.leaflet-bar').style.display = 'none'

        setLeafletOfflineInitialized(true)
      }
    }
  }

  const handleClosePopup = () => {
    if (mapRef.current) {
      const mapCenter = mapRef.current.getCenter()
      const clickedZoom = mapRef.current.getZoom()

      localStorage.setItem('lat', mapCenter.lat)
      localStorage.setItem('lng', mapCenter.lng)
      localStorage.setItem('zoom', clickedZoom)

      setSearchParams({
        lat: mapCenter.lat.toFixed(6),
        lng: mapCenter.lng.toFixed(6),
        zoom: clickedZoom
      })

      localStorage.removeItem('id')
      dispatch(setSelectedMarkerData(null))
      setPopupComponent('')
      setTimeout(() => {
        dispatch(setMarkerUpdate(true))
      }, 500)
      dispatch(deleteProfileItem())
      showCounter()
    }
  }

  const handleMarkerCloseOnDrag = () => {
    if (localStorage.getItem('id') !== null) {
      mapRef.current.off('dragend', handleMarkerCloseOnDrag)

      handleClosePopup()
    }
  }

  const HandlePopupEvents = () => {
    useMapEvent('popupclose', () => {
      mapRef.current.off('dragend', handleMarkerCloseOnDrag)

      handleClosePopup()
    })

    return null
  }

  useEffect(() => {
    if (selectedMarkerData) {
      const markerPopupCoords = selectedMarkerData?.id === activeProfile?.id
      && !Number.isNaN(xmppLiveLocation.lat)
      && selectedMarkerData.location_type[LOCATION_TYPE.LiveLocation] === 0
        ? liveLocCoord
        : selectedMarkerData?.coordinates

      mapRef.current.setView(
        markerPopupCoords,
        mapRef.current.getZoom(),
        { animate: true }
      )

      setTimeout(() => {
        hideCounter()
        setTimeout(() => setSearchParams({ id: selectedMarkerData.id }), 500)

        setPopupComponent(
          <MarkerPopup
            selectedMarkerData={selectedMarkerData}
            markerPopupCoords={markerPopupCoords}
          />
        )

        mapRef.current.on('dragend', handleMarkerCloseOnDrag)
      }, 500)
    }
  }, [selectedMarkerData])

  // Kindly comment this useEffect if something goes wrong
  useEffect(() => {
    // Maybe the current leaflet offline doesn't support the use of WorldCopyJump?
    // This app's map is using WorldCopyJump which results to
    // the map bounds getting incorrect longitude on
    // initial load on or near the intl dateline
    // longitude can only have values < 181 and > -181

    const mapRefCurrent = mapRef?.current
    // eslint-disable-next-line no-underscore-dangle
    const boundsNE = mapRefCurrent?.getBounds()?._northEast
    // eslint-disable-next-line no-underscore-dangle
    const boundsSW = mapRefCurrent?.getBounds()?._southWest

    if (
      mapRefCurrent
      && !leafletOfflineInitialized
      && boundsNE?.lng < 181
      && boundsNE?.lng > -181
      && boundsSW?.lng < 181
      && boundsSW?.lng > -181
    ) {
      autoSaveTiles()
    }
  }, [mapRef?.current?.getBounds()])

  useEffect(() => {
    if (mapRef?.current) {
      const map = mapRef.current
      map.on('moveend', handleMapMoveEnd)

      handleInitialCenter()
    }
  }, [mapRef?.current])

  useEffect(() => {
    if (
      isLiteralNumber(lat)
      && isLiteralNumber(lng)
      && isLiteralNumber(zoom)
    ) {
      localStorage.setItem('lat', lat)
      localStorage.setItem('lng', lng)
      localStorage.setItem('zoom', zoom)
    }
  }, [])

  const northBound = Leaflet.latLng(85, Infinity)
  const southBound = Leaflet.latLng(-85, -Infinity)
  const fullBound = Leaflet.latLngBounds(northBound, southBound)

  return (
    <StyledMapContainer>
      <MapContainer
        style={{ width: '100%', height: '100%' }}
        center={INITIAL_POSITION}
        zoom={ZOOM_LEVEL}
        minZoom={4}
        ref={mapRef}
        worldCopyJump
        maxBoundsViscosity={1.0}
        maxBounds={fullBound}
        attributionControl={false}
        whenReady={(e) => {
          setTimeout(() => {
            localStorage.setItem('northeast', JSON.stringify(e.target.getBounds().getNorthEast()))
            localStorage.setItem('southwest', JSON.stringify(e.target.getBounds().getSouthWest()))
          }, 1000)
        }}
      >
        <TileLayer
          attribution={MAP_ATTRIBUTION}
          url={MAP_URL}
          maxNativeZoom={22}
          maxZoom={UNSUPPORTED_MAX_ZOOM_LEVEL}
          eventHandlers={{
            // when tile loading fails (this does not include invalid keys)
            tileerror: () => {
              dispatch(getNotificationMessage({
                type: REJECTED,
                message: 'Map tile error.',
                isEditProfile: false
              }))
            }
          }}
        />
        <MarkerClusterGroup
          showCoverageOnHover={false}
          animate
          chunkedLoading
          className="marker-cluster-custom"
          spiderfyOnMaxZoom={false}
          disableClusteringAtZoom={14}
          maxClusterRadius={20}
          eventHandlers={{
            animationend: () => {
              if (window.location.search.includes('?id')) {
                const popUpExists = document.querySelector('.leaflet-popup')

                if (!popUpExists) {
                  const mapCenter = mapRef.current.getCenter()
                  const clickedZoom = mapRef.current.getZoom()

                  localStorage.setItem('lat', mapCenter.lat)
                  localStorage.setItem('lng', mapCenter.lng)
                  localStorage.setItem('zoom', clickedZoom)

                  setSearchParams({
                    lat: mapCenter.lat.toFixed(6),
                    lng: mapCenter.lng.toFixed(6),
                    zoom: clickedZoom
                  })

                  localStorage.removeItem('id')

                  setTimeout(() => {
                    setShowMarkerCount('flex')
                  }, 500)
                }
              }
            }
          }}
        >
          {user ? (
            <Markers
              markers={markers}
              mapRef={mapRef}
            />
          ) : (
            markers.map((item) => {
              const trueLng = item.coordinates[1] > 0
                ? item.coordinates[1] - 360
                : item.coordinates[1] + 360

              return (
                <>
                  <Marker
                    key={uuid()}
                    position={item.coordinates}
                    // eslint-disable-next-line max-len
                    icon={iconMarkerImage(false, htmlTemplate(null, ImagesPng.BlackMarker, null))}
                  />

                  <Marker
                    key={item.id}
                    position={[item.coordinates[0], trueLng]}
                    // eslint-disable-next-line max-len
                    icon={iconMarkerImage(false, htmlTemplate(null, ImagesPng.BlackMarker, null))}
                  />
                </>
              )
            })
          )}
        </MarkerClusterGroup>
        { popupComponent }
        <HandlePopupEvents />
      </MapContainer>
    </StyledMapContainer>
  )
}
