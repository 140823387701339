import { Box, Spinner } from '@chakra-ui/react'

export const LoadingSpinier = ({
  width = '100px',
  height = '100px',
  marginTop = '0'
}) => (
  <Box position="relative" width="100%" height="100%">
    <Box
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
    >
      <Spinner
        width={width}
        height={height}
        marginTop={marginTop}
      />
    </Box>
  </Box>
)
