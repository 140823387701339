import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Image,
  Stack
} from '@chakra-ui/react'
import {
  getBlogPreference,
  getPosts,
  setBlogParent,
  setNotificationSectionPageTitle,
  setPosts
} from 'store'
import { v4 as uuid } from 'uuid'
import { ImagesPng } from 'assets/images'

export const CommunityBlogs = ({
  NotificationSections,
  preferredBlogs,
  rowTitle,
  setNotificationSectionPage
}) => {
  const dispatch = useDispatch()

  return (
    <Card key={uuid()} boxShadow="none" marginTop="0px !important" background="#c4c3c7">
      <CardHeader display="flex" alignItems="center" padding="0 25px" background="#feeb88">
        <Box textAlign="left" paddingRight="10px">
          {rowTitle}
        </Box>
      </CardHeader>

      <CardBody padding="5px" minHeight="130px">
        <Box
          sx={{
            overflowX: 'auto',
            display: 'flex',
            flexDirection: 'row',
            gap: '0px'
          }}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
              height: '10px',
              display: 'block'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '24px',
              background: 'rgba(255, 215, 0, 1)'
            }
          }}
        >
          <Stack
            spacing="55px"
            id="community-blog-section"
            paddingBottom={preferredBlogs.length > 3 ? '5px' : null}
            marginLeft="5px"
            marginRight="5px"
            sx={{
              overflowX: 'auto',
              display: 'flex',
              flexDirection: 'row',
              gap: '0px',
              overflowAnchor: 'auto !important'
            }}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
                height: '10px',
                display: 'block'
              },
              '&::-webkit-scrollbar-track': {
                width: '6px'
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '24px',
                background: 'rgba(255, 215, 0, 1)'
              }
            }}
          >
            {preferredBlogs.map((item) => (
              <Stack key={uuid()}>
                <Box
                  key={uuid()}
                  sx={{
                    minWidth: '120px',
                    marginTop: '5px'
                  }}
                  data-name="preferredBlog"
                  position="relative"
                >
                  <Image
                    key={uuid()}
                    cursor="pointer"
                    width="108px"
                    height="108px"
                    objectFit="cover"
                    src={item.blogImage ?? ImagesPng.DefaultImage}
                    alt={item.title ?? 'blog image'}
                    borderRadius="5px"
                    onClick={() => {
                      dispatch(setPosts([]))
                      dispatch(setBlogParent([]))

                      if (item.type === 'friends') {
                        dispatch(getPosts({
                          type: item.type
                        }))
                        dispatch(setNotificationSectionPageTitle(item.title))
                        dispatch(setNotificationSectionPage(NotificationSections.BLOG_LIST))
                      } else if (item.type === 'affinity') {
                        dispatch(getPosts({
                          type: item.type,
                          id: item.id
                        }))
                        dispatch(setNotificationSectionPageTitle(item.title))
                        dispatch(setNotificationSectionPage(NotificationSections.BLOG_LIST))
                      } else {
                        dispatch(getBlogPreference())
                        dispatch(setNotificationSectionPageTitle(''))
                        dispatch(setNotificationSectionPage(NotificationSections.BLOG_PREFERENCE))
                      }
                    }}
                  />
                </Box>
              </Stack>
            ))}
          </Stack>
        </Box>
      </CardBody>
    </Card>
  )
}
