import { useDispatch } from 'react-redux'
import {
  Box,
  Image, Stack, Text
} from '@chakra-ui/react'
import { ImagesPng } from 'assets/images'

export const UserInformation = ({
  profilePicture,
  coverPicture,
  fullName,
  lastAccessed,
  // setUserSectionPage,
  quote
}) => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const date = new Date(lastAccessed)
  // eslint-disable-next-line no-unused-vars
  const currentDate = new Date()

  return (
    <>
      <Stack
        width="100%"
        position="relative"
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Box
          // backgroundColor="gray"
          backgroundImage={coverPicture || null}
          backgroundColor={null || 'gray.700'}
          bgPosition="100%"
          bgRepeat="no-repeat"
          bgSize="455px 110px"
          zIndex={-1}
          position="absolute"
          width="146%"
          height="95px"
          padding="20px"
          top="-24px"
          left={-20}
          // right={-20}
          data-name="coverPicture"
          sx={{
            '@media (max-width: 470px)': {
              left: '-20',
              width: '100vw'
            }
          }}
        >
          <Box />
        </Box>

        <Stack
          alignItems="center"
          direction="row"
          spacing="14px"
          position="relative"
          width="100%"
          sx={{
            '@media (max-width: 480px)': {
              left: '-40px !important'
            }
          }}
        >
          <Image
            boxSize="65px"
            position="relative"
            objectFit="cover"
            src={profilePicture || ImagesPng.DefaultImage}
            alt="User Image"
            borderRadius="full"
            top="-10px"
          />
          <Stack direction="column" spacing="12px" position="relative" top="-10px" width="100%">
            <Text
              background="rgba(211, 211, 211, .9)"
              p="1.5px 15px"
              borderRadius={3}
              data-name="fullName"
              width="auto"
              fontSize="17px"
            >
              {fullName}
            </Text>
            {/* <Text color={COLORS.gray} fontSize="12px">
              Last accessed:
              {' '}
              {lastAccessed}
            </Text> */}
          </Stack>
        </Stack>

        {/* <Button
          _active="unset"
          _hover="unset"
          backgroundColor="unset"
          onClick={() => dispatch(setUserSectionPage(EDIT_PROFILE))}
        >
          <EditIcon />
        </Button> */}
        <Box />
      </Stack>
      <Stack direction="column" spacing="10px" sx={{ marginTop: '5px !important' }}>
        <Text align="center" data-name="quote">
          { quote === null ? '' : quote}
        </Text>
      </Stack>
    </>
  )
}
