import {
  useEffect,
  useState
} from 'react'
import {
  AtomicBlockUtils,
  convertFromRaw,
  convertToRaw,
  EditorState
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import {
  Box,
  Image,
  Stack
} from '@chakra-ui/react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { COLORS } from 'utils/styles'
import { CloseIcon } from 'assets/icons'

const ImageComponent = ({ block, contentState, blockProps }) => {
  const entity = contentState.getEntity(block.getEntityAt(0))
  const { src } = entity.getData()
  const [showX, setShowX] = useState(false)

  return (
    <div style={{ textAlign: 'center' }}>
      <Stack
        textAlign="center"
        position="relative"
        display="inline-block"
        onMouseEnter={() => setShowX(true)}
        onMouseLeave={() => setShowX(false)}
      >
        <Image
          src={src}
          maxWidth="100%"
          cursor="default"
        />
        <Stack
          aria-label="Remove image"
          backgroundColor="rgba(255, 255, 255, 0.8)"
          padding="2px"
          position="absolute"
          top="2"
          right="2"
          cursor="pointer"
          borderRadius="5px"
          display={showX ? 'flex' : 'none'}
          onClick={() => blockProps.removeImage(block.getKey())}
        >
          <CloseIcon />
        </Stack>
      </Stack>
    </div>
  )
}

export const TextEditor = ({
  allowedFiles,
  editMode,
  editPostData,
  uploadedImg,
  setUploadedImgCount,
  setBody,
  setUploadedImg
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const insertImage = (url) => {
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: url })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      ' '
    )

    setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getSelection()))
  }

  const handleImageUpload = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const url = reader.result
      insertImage(url)
      resolve({ data: { url } })
    }

    reader.onerror = (reason) => reject(reason)

    reader.readAsDataURL(file)
  })

  const removeImage = (blockKey) => {
    const contentState = editorState.getCurrentContent()
    const blockMap = contentState.getBlockMap().delete(blockKey)
    const newContentState = contentState.merge({
      blockMap,
      selectionAfter: contentState.getSelectionAfter()
    })

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'remove-range'
    )

    setEditorState(newEditorState)
  }

  useEffect(() => {
    if (uploadedImg !== '') {
      handleImageUpload(uploadedImg)
    }

    setUploadedImg('')
  }, [uploadedImg])

  useEffect(() => {
    setBody(convertToRaw(editorState.getCurrentContent()))
  }, [editorState])

  useEffect(() => {
    if (editMode && editPostData.id) {
      const contentState = convertFromRaw(editPostData.body)
      setEditorState(EditorState.createWithContent(contentState))
    }
  }, [editPostData])

  return (
    <Box
      border="solid 2px transparent"
      borderRadius="5px"
      transition="0.2s"
      _hover={{
        border: 'solid 1.8px transparent'
      }}
      _focusWithin={{
        borderColor: 'rgb(49, 130, 206)'
      }}
    >
      <Editor
        editorState={editorState}
        onEditorStateChange={(e) => {
          setEditorState(e)
          setUploadedImgCount(Object.keys(convertToRaw(e.getCurrentContent()).entityMap).length)
        }}
        stripPastedStyless
        blockRendererFn={(block) => {
          if (block.getType() === 'atomic') {
            return {
              component: ImageComponent,
              editable: false,
              props: {
                removeImage
              }
            }
          }
          return null
        }}
        toolbarHidden
        toolbar={{
          image: {
            urlEnabled: false,
            inputAccept: { allowedFiles },
            uploadCallback: handleImageUpload,
            alt: { present: false, mandatory: false }
          }
        }}
        editorStyle={{
          background: COLORS.lighterGray,
          width: '100%',
          height: 'auto',
          minHeight: '5em',
          overflow: 'hidden',
          resize: 'none',
          padding: '0 3% 3% 3%',
          borderRadius: '5px',
          outline: 'none',
          transition: 'border-color 0.2s',
          border: 'solid 2px transparent',
          cursor: 'text'
        }}
        placeholder="Body here"
        name="blog-body"
      />
    </Box>
  )
}
