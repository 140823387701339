import { Box } from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import styled from 'styled-components'

export const CustomPill = styled(Box)`
  border-radius: 9999px;
  background-color: ${COLORS.gold};
  padding: 6px 10px;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;

  :hover {
    background: ${COLORS.gold_hover};
  }
`
