import { Box, Input } from '@chakra-ui/react'
import { AddImageIcon } from 'assets/icons'
import { ACCEPTED_IMG_LIST } from 'utils/constants'

export const ProfileCoverImage = ({ inputFileRef, handleUploadFile }) => (
  /* eslint-disable jsx-a11y/label-has-associated-control */
  <>
    <Box>
      <Box
        position="absolute"
        bottom="5px"
        right="-17%"
      >
        <label
          style={{ cursor: 'pointer' }}
          name="add-banner"
          htmlFor="userBackground"
        >
          <AddImageIcon />
        </label>
      </Box>
    </Box>
    <Input
      display="none"
      ref={inputFileRef}
      id="userBackground"
      type="file"
      onChange={handleUploadFile}
      accept={ACCEPTED_IMG_LIST}
    />
  </>
)
