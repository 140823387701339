import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Image,
  Stack,
  Text
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { ImagesPng } from 'assets/images'
import {
  getComments,
  likeComment,
  setBlogParent,
  setComments
} from 'store'
import {
  EditIcon2,
  LikeIcon,
  CommentIcon,
  RepostIcon,
  ShareIcon
} from 'assets/icons'
import { LoadingSpinier } from 'common/Loading'
import { PENDING } from 'utils/constants'
import { formattedDate } from 'utils/helpers'
import { LikeButtonAnimation } from './styled'

export const CommentsForPost = ({
  selectedUser,
  comments,
  getCommentsStatus,
  setEditCommentData,
  commentsOption,
  blogParent
}) => {
  const dispatch = useDispatch()
  const [animate, setAnimate] = useState([])
  const [loadingMore, setLoadingMore] = useState(false)
  const [oldCommentsCount, setOldCommentsCount] = useState(0)
  const observer = useRef()
  const secondToLastItem = useRef(null)

  const animateLike = (id) => {
    setAnimate((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }))

    setTimeout(() => {
      setAnimate((prevState) => ({
        ...prevState,
        [id]: !prevState[id]
      }))
    }, 500)
  }

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      // The "10" is the limit set on the backend DB query
      if (entries[0].isIntersecting && comments.length - oldCommentsCount >= 10) {
        observer.current.unobserve(secondToLastItem.current)
        setLoadingMore(true)
        setOldCommentsCount(comments.length)
        dispatch(getComments({
          ...commentsOption,
          lastItemId: comments.at(-1).comment_id
        }))
      }
    })

    const currentObserver = observer.current
    if (secondToLastItem.current && comments.length - oldCommentsCount >= 10) {
      currentObserver.observe(secondToLastItem.current)
    }

    setLoadingMore(false)

    return () => {
      if (secondToLastItem.current) {
        observer.current.disconnect()
      }
    }
  }, [comments])

  useEffect(() => {
    setOldCommentsCount(0)
  }, [blogParent])

  return (
    <Stack marginTop="2%">
      {
        getCommentsStatus === PENDING && !comments.length
          ? (
            <Stack marginTop="100px">
              <LoadingSpinier />
            </Stack>
          ) : ''
      }
      {
        comments && comments.length
          ? comments.map((item, index) => (
            <Stack
              ref={comments.length - 2 === index ? secondToLastItem : null}
              backgroundColor="rgb(229, 229, 229)"
              width="calc(100% + 112px)"
              borderRadius="3px"
              border="solid 1px black"
              padding="10px"
              display="flex"
              justifyContent="flex-start"
              marginLeft="-56px"
            >
              {
                selectedUser.full_name === item.full_name
                  ? (
                    <Stack
                      width="15px"
                      height="15px"
                      marginLeft="auto"
                      cursor="pointer"
                      onClick={() => {
                        setEditCommentData({})
                        setEditCommentData(item)
                      }}
                    >
                      <EditIcon2 />
                    </Stack>
                  ) : ''
              }

              <Stack
                alignItems="center"
                display="inline"
                width="auto"
                justifyContent="flex-start"
                marginLeft="30px"
              >
                <Image
                  boxSize="55px"
                  position="relative"
                  objectFit="cover"
                  src={item.profile_picture || ImagesPng.DefaultImage}
                  alt="User Image"
                  borderRadius="full"
                  display="inline-flex"
                  verticalAlign="bottom"
                />
                <Text
                  display="inline-flex"
                  marginLeft="10px"
                  fontSize="16px"
                  lineHeight="28px"
                  wordBreak="break-word"
                >
                  {item.full_name}
                  <br />
                  {formattedDate(item.created_at)}
                </Text>
              </Stack>

              <Text
                whiteSpace="pre-wrap"
              >
                {item.body}
              </Text>

              <Stack
                direction="row"
                borderTop="solid 1px black"
                width="calc(100% + 20px)"
                padding="3.5px 0px"
                justifyContent="space-around"
                marginBottom="-10px"
                marginLeft="-10px"
              >
                <LikeButtonAnimation className={animate[item.comment_id] ? 'animate' : ''}>
                  <Stack
                    as="button"
                    onClick={() => {
                      animateLike(item.comment_id)
                      dispatch(likeComment(item.comment_id))
                    }}
                  >
                    <LikeIcon fill={item.likedByMe ? COLORS.gold : 'none'} />
                  </Stack>

                  <Text>{item.likes}</Text>
                </LikeButtonAnimation>

                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <Stack
                    as="button"
                    onClick={() => {
                      dispatch(setComments([]))
                      dispatch(setBlogParent([...blogParent, item]))
                      dispatch(getComments({
                        type: 'comment',
                        comment_id: item.comment_id
                      }))
                    }}
                  >
                    <CommentIcon />
                  </Stack>
                  <Text>{item.comments}</Text>
                </Stack>

                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <RepostIcon />
                  <Text>0</Text>
                </Stack>

                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <ShareIcon />
                  <Text>0</Text>
                </Stack>
              </Stack>
            </Stack>
          ))
          : (
            <Stack>
              {
                getCommentsStatus === PENDING && !comments.length
                  ? ''
                  : (
                    <Text
                      textAlign="center"
                      backgroundColor="rgb(229, 229, 229)"
                      width="calc(100% + 110px)"
                      borderRadius="3px"
                      border="solid 1px black"
                      padding="10px"
                      marginLeft="-56px"
                    >
                      There are no comments. Be the first one to comment
                    </Text>
                  )
              }
            </Stack>
          )
      }
      {
        loadingMore && getCommentsStatus === PENDING
          ? (
            <Stack margin="75px 0 50px 0">
              <LoadingSpinier />
            </Stack>
          ) : ''
      }
    </Stack>
  )
}
