import styled, { keyframes } from 'styled-components/macro'

const bounce = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

export const LikeButtonAnimation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  transition: fill 0.3s ease;

  &.animate {
    animation: ${bounce} 0.4s ease;
  }
`
