import { useDispatch } from 'react-redux'
import {
  Box,
  Checkbox,
  Stack,
  Text
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import {
  addBlogPreference,
  blogPreferenceAllContacts,
  removeBlogPreference,
  setGetBlogPreferenceStatus
} from 'store'
import { LoadingSpinier } from 'common/Loading'
import { PENDING } from 'utils/constants'

import { Affinities } from 'components/Notifications/CommunityBlogs/Affinities'

export const BlogPreference = ({
  blogPreference,
  getBlogPreferenceStatus
}) => {
  const dispatch = useDispatch()
  const {
    active,
    inactive,
    all_contacts
  } = blogPreference

  if (getBlogPreferenceStatus === PENDING && !blogPreference.length) {
    return (
      <Stack marginTop="100px">
        <LoadingSpinier />
      </Stack>
    )
  }

  const getAllContacts = () => {
    dispatch(setGetBlogPreferenceStatus(PENDING))
    dispatch(blogPreferenceAllContacts())
  }

  const addPreference = (id) => {
    dispatch(setGetBlogPreferenceStatus(PENDING))
    dispatch(addBlogPreference(id))
  }

  const removePreference = (id) => {
    dispatch(setGetBlogPreferenceStatus(PENDING))
    dispatch(removeBlogPreference(id))
  }

  return (
    <Stack marginTop="4%">
      <Stack
        width="92%"
        borderRadius="3px"
        padding="10px"
        display="flex"
        justifyContent="flex-start"
        margin="0 4%"
      >
        <Text
          backgroundColor="rgb(134, 134, 134)"
          padding="0"
          align="center"
          fontSize="20px"
          color="white"
          letterSpacing="-.5px"
          fontFamily="sans-serif"
          fontWeight="600"
          style={{
            WebkitTextStroke: '.8px black'
          }}
        >
          Current
        </Text>

        <Stack
          padding="0 20px"
          height="200px"
          overflow="auto"
        >
          <Stack
            display="flex"
            justifyContent="flex-start"
            flexDirection="row"
            margin="2.5px 0"
          >
            <Text
              color="rgb(123, 123, 123)"
              display="inline-flex"
            >
              All Contacts
            </Text>
            <Checkbox
              icon={<Box />}
              color="red"
              colorScheme="yellow"
              display="inline-flex"
              marginLeft="auto"
              defaultChecked={all_contacts !== 0}
              onChange={() => getAllContacts()}
            />
          </Stack>

          {
            active.map((item) => (
              <Stack
                display="flex"
                justifyContent="flex-start"
                flexDirection="row"
                margin="2.5px 0"
              >
                <Text
                  color="rgb(123, 123, 123)"
                  display="inline-flex"
                >
                  {item.title}
                </Text>
                <Checkbox
                  icon={<Box />}
                  color="red"
                  colorScheme="yellow"
                  display="inline-flex"
                  marginLeft="auto"
                  defaultChecked
                  onChange={() => removePreference(item.id)}
                />
              </Stack>
            ))
          }
        </Stack>
      </Stack>

      <Stack
        width="92%"
        borderRadius="3px"
        padding="10px"
        display="flex"
        justifyContent="flex-start"
        margin="0 4%"
      >
        <Text
          backgroundColor="rgb(134, 134, 134)"
          padding="0"
          align="center"
          fontSize="18px"
          color="white"
          letterSpacing="-.5px"
          fontFamily="sans-serif"
          fontWeight="600"
          style={{
            WebkitTextStroke: '.8px black'
          }}
        >
          Recent
        </Text>

        <Stack
          padding="0 20px"
          height="200px"
          overflow="auto"
        >
          {
            inactive.map((item) => (
              <Stack
                display="flex"
                justifyContent="flex-start"
                flexDirection="row"
                margin="2.5px 0"
              >
                <Text
                  color="rgb(123, 123, 123)"
                  display="inline-flex"
                >
                  {item.title}
                </Text>
                <Checkbox
                  icon={<Box />}
                  color="red"
                  colorScheme="yellow"
                  display="inline-flex"
                  marginLeft="auto"
                  onChange={() => addPreference(item.id)}
                />
              </Stack>
            ))
          }
        </Stack>
      </Stack>

      <Stack
        width="92%"
        borderRadius="3px"
        padding="10px"
        display="flex"
        justifyContent="flex-start"
        margin="0 4%"
      >
        <Text
          backgroundColor="rgb(134, 134, 134)"
          padding="0"
          align="center"
          fontSize="18px"
          color="white"
          letterSpacing="-.5px"
          fontFamily="sans-serif"
          fontWeight="600"
          style={{
            WebkitTextStroke: '.8px black'
          }}
        >
          Search
        </Text>

        <Stack padding="0 20px">
          <Affinities
            activeBlogPref={active.map((item) => item.title)}
            addPreference={addPreference}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
