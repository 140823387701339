import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Image,
  Input,
  Stack,
  Text
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { ImagesPng } from 'assets/images'
import {
  addImagePosts,
  getNotificationMessage,
  postPosts,
  putPosts
} from 'store'
import {
  ACCEPTED_IMG_LIST,
  PENDING,
  REJECTED,
  SUCCEEDED
} from 'utils/constants'
import { CloseIcon } from 'assets/icons'
import { Affinities } from 'components/UserSection/ProfileBlog/Affinities'
import { LoadingSpinier } from 'common/Loading'
import { base64ToFile, resizeImage } from 'utils/helpers'
import { CustomPill } from './styled'
import { TextEditor } from './TextEditor'

export const ProfileBlogForm = ({
  profileId,
  profilePicture,
  editPostData,
  setEditPostData,
  postPostsStatus,
  putPostsStatus,
  addImagePostsStatus,
  affinityList,
  getPostsStatus
}) => {
  const dispatch = useDispatch()
  const [editMode, setEditMode] = useState(false)
  const [archiveText, setArchiveText] = useState('Archive')
  const [planText, setPlanText] = useState('Plan')
  const [affinitiesArray, setAffinitiesArray] = useState([])
  const [chooseAffi, setChooseAffi] = useState(false)
  const [uploadedImg, setUploadedImg] = useState('')
  const [uploadedImgCount, setUploadedImgCount] = useState(0)
  const parentStackRef = useRef(null)
  const formTitleRef = useRef(null)
  const imageInputRef = useRef(null)

  const [title, setTitle] = useState('')
  const [body, setBody] = useState([])
  const [postCallOnGoing, setPostCallOnGoing] = useState(false)

  const closeForm = () => {
    setTitle('')
    setBody([])
    setEditMode(false)
    setChooseAffi(false)
    setAffinitiesArray([])
    setEditPostData({})
    setUploadedImgCount(0)
  }

  const submitForm = async () => {
    if (title.trim() === '' || body.length) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Title and body field is required',
          isEditProfile: true
        })
      )
    } else {
      let tempBody = JSON.parse(JSON.stringify(body))
      setPostCallOnGoing(true)

      Promise.all(
        Object.values(tempBody.entityMap).map(async (e) => {
          const preview = await dispatch(addImagePosts({
            image: base64ToFile(e.data.src)
          }))

          e.data.src = preview.payload.fileName
        })
      ).then(async () => {
        if (Object.keys(editPostData).length) {
          const data = {
            id: editPostData.profile_id,
            post_id: editPostData.id,
            title,
            body: JSON.stringify(tempBody),
            ...(affinitiesArray.length ? { affinities: [(affinitiesArray.find(() => 1)).id] } : {})
          }

          await dispatch(putPosts(data))
        } else {
          const data = {
            id: profileId,
            title,
            body: JSON.stringify(tempBody),
            ...(affinitiesArray.length ? { affinities: [(affinitiesArray.find(() => 1)).id] } : {})
          }

          await dispatch(postPosts(data))
        }

        setPostCallOnGoing(false)
      }).catch((err) => {
        console.error(err)
        setPostCallOnGoing(false)
      })
    }
  }

  const focusTitleRef = () => {
    if (editMode && formTitleRef.current) {
      formTitleRef.current.blur()
      formTitleRef.current.focus()
    }
  }

  const checkPicture = (reader, files) => {
    reader.onload = (e2) => {
      const imageElement = document.createElement('img')
      imageElement.src = e2.target.result

      imageElement.onload = () => {
        try {
          if (
            files[0]?.size > 500000
            || imageElement?.width > 500
          ) {
            resizeImage(imageElement, 'b', (resizedImage) => {
              if (resizedImage === null) {
                dispatch(
                  getNotificationMessage({
                    type: REJECTED,
                    message: 'Image upload failed',
                    isEditProfile: true
                  })
                )
              } else {
                setUploadedImg(resizedImage)
              }
            })
          } else {
            setUploadedImg(files[0])
          }
        } catch {
          dispatch(
            getNotificationMessage({
              type: REJECTED,
              message: 'Image upload failed',
              isEditProfile: true
            })
          )
        }
      }
    }
  }

  const handleUploadFile = (e) => {
    const { files } = e.target
    const reader = new FileReader()

    if (uploadedImgCount >= 4) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Maximum of 4 images only',
          isEditProfile: true
        })
      )
    } else if (files.length === 0 || files[0].size > 10000000) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'The uploaded image must not exceed 10MB',
          isEditProfile: true
        })
      )
    } else {
      reader.readAsDataURL(files[0])
      checkPicture(reader, files)
    }

    setTimeout(() => {
      imageInputRef.current.value = ''
    }, 500)
  }

  useEffect(() => {
    if (editMode && formTitleRef.current) {
      focusTitleRef()
    }
  }, [editMode])

  useEffect(() => {
    if (Object.keys(editPostData).length !== 0) {
      setEditMode(true)
      setTitle(editPostData.title)
      setBody(editPostData.body)
      setAffinitiesArray(editPostData.affinities)
      focusTitleRef()
      setUploadedImgCount(Object.keys(editPostData.body.entityMap).length)
    }
  }, [editPostData])

  useEffect(() => {
    if (postPostsStatus === SUCCEEDED) {
      closeForm()
    }
  }, [postPostsStatus])

  useEffect(() => {
    if (putPostsStatus === SUCCEEDED) {
      closeForm()
    }
  }, [putPostsStatus])

  return (
    <Stack
      ref={parentStackRef}
      background={COLORS.grayGradientTopToBot}
      width="calc(100% + 112px)"
      marginLeft="-56px"
      marginRight="auto"
      marginTop="15px"
      borderRadius="3px"
      padding="8px"
    >
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="row"
      >
        <Image
          boxSize="45px"
          objectFit="cover"
          src={profilePicture || ImagesPng.DefaultImage}
          alt="User Image"
          borderRadius="full"
          display="inline-flex"
        />
        {
          editMode
            ? (
              <Input
                ref={formTitleRef}
                background={COLORS.lighterGray}
                color="rgb(100, 100, 100)"
                padding="8px"
                borderRadius={3}
                flex="1"
                maxWidth="calc(100% - 51px)"
                height="35px"
                display="inline-flex"
                alignItems="center"
                placeholder="Title here"
                name="blog-title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            )
            : (
              <Text
                background={COLORS.lighterGray}
                color="rgb(150, 150, 150)"
                padding="8px"
                borderRadius={3}
                flex="1"
                maxWidth="calc(100% - 51px)"
                height="35px"
                display="inline-flex"
                alignItems="center"
                cursor="pointer"
                onClick={() => setEditMode(true)}
                _hover={{
                  background: 'rgb(220, 220, 220)',
                  border: 'solid 1px black'
                }}
                transition="0.3s"
              >
                Let's share something
              </Text>
            )
        }
      </Stack>
      {
        editMode
          ? (
            <>
              <Stack position="relative">
                <Input
                  ref={imageInputRef}
                  type="file"
                  accept={ACCEPTED_IMG_LIST}
                  display="none"
                  onChange={handleUploadFile}
                />
                <TextEditor
                  allowedFiles={ACCEPTED_IMG_LIST}
                  editMode={editMode}
                  editPostData={editPostData}
                  uploadedImg={uploadedImg}
                  setUploadedImgCount={setUploadedImgCount}
                  setBody={setBody}
                  setUploadedImg={setUploadedImg}
                />
                <Stack
                  rowGap="10px"
                  spacing="unset"
                  wrap="wrap"
                  direction="row"
                  display={affinitiesArray.length > 0 ? 'flex' : 'none'}
                  position="absolute"
                  left="5px"
                  bottom="5px"
                  zIndex="5"
                >
                  {affinitiesArray.map((item) => (
                    <Stack
                      direction="row"
                      width="max-content"
                      alignItems="center"
                      color="#F8F8FF"
                      border="solid 1px black"
                      borderRadius="5px"
                      bg={COLORS.gold}
                      p="0 2px"
                      data-name="blog-affinity"
                      fontSize="11px"
                      cursor="pointer"
                      pointerEvents={chooseAffi ? 'auto' : 'none'}
                      onClick={() => setAffinitiesArray([])}
                    >
                      <Text data-name="affinity-title">{item.title}</Text>
                      <Stack marginLeft="-8px" marginRight="2px" width="11px" height="11px">
                        <CloseIcon fill="#F8F8FF" />
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                padding="2.5px 0px"
                justifyContent="space-between"
                margin="-2.5px 0"
                fontSize="13px"
                overflowX="auto"
              >
                <CustomPill
                  data-name="blog-cancel"
                  onClick={() => closeForm()}
                  pointerEvents={putPostsStatus !== PENDING && postPostsStatus !== PENDING ? 'auto' : 'none'}
                >
                  Cancel
                </CustomPill>
                <CustomPill
                  data-name="blog-media"
                  onClick={() => imageInputRef.current.click()}
                >
                  Media
                </CustomPill>
                <CustomPill
                  data-name="blog-affinity"
                  onClick={() => {
                    if (chooseAffi) {
                      setChooseAffi(false)
                    } else {
                      setChooseAffi(true)
                    }
                  }}
                >
                  Affinity
                </CustomPill>
                <CustomPill
                  data-name="blog-archive"
                  onClick={() => {
                    setArchiveText('Coming soon')
                    setTimeout(() => { setArchiveText('Archive') }, 3000)
                  }}
                >
                  { archiveText }
                </CustomPill>
                <CustomPill
                  data-name="blog-plan"
                  onClick={() => {
                    setPlanText('Coming soon')
                    setTimeout(() => { setPlanText('Plan') }, 3000)
                  }}
                >
                  { planText }
                </CustomPill>
                <CustomPill
                  data-name="blog-post"
                  pointerEvents={
                    getPostsStatus !== PENDING
                    && putPostsStatus !== PENDING
                    && postPostsStatus !== PENDING
                      ? 'auto'
                      : 'none'
                  }
                  onClick={() => submitForm()}
                >
                  {
                    putPostsStatus === PENDING
                    || postPostsStatus === PENDING
                    || addImagePostsStatus === PENDING
                    || postCallOnGoing
                      ? (
                        <Stack>
                          <LoadingSpinier
                            width="10px"
                            height="10px"
                            marginTop="23px"
                          />
                        </Stack>
                      ) : 'Post'
                  }
                </CustomPill>
              </Stack>
              {
                chooseAffi
                  ? (
                    <Stack>
                      <Affinities
                        affinitiesArray={affinitiesArray}
                        setAffinitiesArray={setAffinitiesArray}
                        affinityList={affinityList}
                      />
                    </Stack>
                  ) : ''
              }
            </>
          )
          : ''
      }
    </Stack>
  )
}
