import { useState } from 'react'
import { Text, Stack } from '@chakra-ui/react'
import { CreateComment } from 'components/Notifications/CommunityBlogs/Comments/CreateComment'
import { CommentsForPost } from 'components/Notifications/CommunityBlogs/Comments/CommentsForPost'
import { COLORS } from 'utils/styles'

export const Comments = ({
  activeProfile,
  comments,
  getCommentsStatus,
  postCommentsStatus,
  putCommentsStatus,
  blogParent,
  commentsOption,
  postsOption
}) => {
  const [editCommentData, setEditCommentData] = useState({})

  return (
    <Stack direction="column">
      <Stack
        display="flex"
        justifyContent="center"
        direction="row"
        margin={postsOption?.type === 'marker' ? '-7% 0 -2% 0' : '2% 0'}
      >
        <Text
          background={COLORS.lightGoldGradientLeftToRight}
          padding="2px 5%"
          fontSize="19px"
          width="85%"
          borderRadius="5px"
          align="center"
          letterSpacing=".15px"
          fontWeight="400"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {blogParent[blogParent.length - 1].title ?? blogParent[blogParent.length - 1].body}
        </Text>
      </Stack>

      <CreateComment
        activeProfile={activeProfile}
        blogParent={blogParent}
        getCommentsStatus={getCommentsStatus}
        type={commentsOption.type}
        postCommentsStatus={postCommentsStatus}
        putCommentsStatus={putCommentsStatus}
        editCommentData={editCommentData}
        setEditCommentData={setEditCommentData}
      />

      <CommentsForPost
        activeProfile={activeProfile}
        comments={comments}
        getCommentsStatus={getCommentsStatus}
        setEditCommentData={setEditCommentData}
        commentsOption={commentsOption}
        postsOption={postsOption}
        blogParent={blogParent}
      />
    </Stack>
  )
}
