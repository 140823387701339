import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  IconButton,
  Image,
  Stack,
  Text,
  useDisclosure,
  useOutsideClick
} from '@chakra-ui/react'
import { ArrowIcon, SettingsIcon } from 'assets/icons'
import { ImagesPng } from 'assets/images'
import { withAuthentication } from 'HOC/IsUserAuth'
import { motion } from 'framer-motion'
import L from 'leaflet'
import {
  authSelectors,
  chatCredentialsSelectors,
  getNotificationMessage,
  handleOpenModal,
  markersSelectors,
  setAuthPage,
  setBlogParent,
  getComments,
  setComments,
  setIsProfileSwitched,
  setLiveLocData,
  setSocMedDisplay,
  setSocMedDisplayStatus,
  setUserSectionPage,
  usersSelectors,
  getUserProfile
} from 'store'
import { liveMarkerUpdate, staticMarkerUpdate } from 'store/markers/axios'
import {
  EDIT_PROFILE,
  MODAL_SECTION,
  MY_PROFILES,
  REJECTED,
  SETTINGS,
  SIGN_IN,
  SINGLE_PROFILE,
  VERIFY_PAGE,
  LOCATION_TYPE,
  PENDING,
  PROFILE_BLOG
} from 'utils/constants'
import { getUrlParams, isAuth, isIOS } from 'utils/helpers'
import { COLORS } from 'utils/styles'

/* eslint-disable import/no-cycle */
import { Messenger, UserSection } from 'components'
/* eslint-enable import/no-cycle */
import { SearchContainer } from 'components/SearchContainer'

import { CropPicture } from './CropPicture'
import { DiscardChanges } from './DiscardChanges'

export const Header = () => {
  const { modalTitle } = useSelector(authSelectors)
  const {
    user,
    activeProfile,
    blogParent,
    getCommentsStatus,
    isCloseModal,
    isSocialMediaSelectOpen,
    isProfileChanged,
    isProfileActive,
    isProfileIncomplete,
    isLocationOpen,
    isProfileDeleted,
    isPictureUploaded,
    closeResetProfileModal,
    cropFor,
    isProfileSwitched
  } = useSelector(usersSelectors)
  const {
    liveMarkerExp, staticMarkerExp
  } = useSelector(markersSelectors)

  const { xmppLiveLocation } = useSelector(chatCredentialsSelectors)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const btnRef = useRef()
  const drawerRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isDiscard, setIsDiscard] = useState(false)
  const [isSetting, setIsSetting] = useState(false)
  const [isClickedOutside, setIsClickedOutside] = useState(true)
  const [isCloseable, setIsCloseable] = useState(true)
  const [closeTheModal, setCloseTheModal] = useState(false)
  const [closeCropModal, setCloseCropModal] = useState(true)
  const [liveReset, setLiveReset] = useState(false)
  const [staticReset, setStaticReset] = useState(false)
  const [updateReset, setUpdateReset] = useState(false)
  let touchStart; let touchEnd

  let id = localStorage.getItem('id')
  let intervalRef = useRef()
  const usePhoto = activeProfile && activeProfile.profile_picture
  if (localStorage.getItem('isLocDisabled') === null) {
    localStorage.setItem('isLocDisabled', false)
  }
  const handleCloseButton = () => {
    setIsClickedOutside(modalTitle !== VERIFY_PAGE.EditProfile)

    if (isAuth() && user) {
      if (modalTitle === VERIFY_PAGE.MyProfiles) {
        onClose()
      } else if (modalTitle === VERIFY_PAGE.EditProfile
        && isProfileChanged === true && isSocialMediaSelectOpen === false) {
        setIsDiscard(true)
      } else if (
        (
          modalTitle === VERIFY_PAGE.EditProfile
          && isProfileChanged === false
          && isSocialMediaSelectOpen === false
        )
        || modalTitle === VERIFY_PAGE.ProfileBlog) {
        if (isProfileActive === true) {
          dispatch(setUserSectionPage(SINGLE_PROFILE))
        } else {
          dispatch(setUserSectionPage(MY_PROFILES))
        }
      } else if (modalTitle === VERIFY_PAGE.EditProfile
        && isSocialMediaSelectOpen === true) {
        dispatch(setUserSectionPage(EDIT_PROFILE))
        dispatch(setSocMedDisplayStatus('none'))
        dispatch(setSocMedDisplay(false))
      } else if (modalTitle === VERIFY_PAGE.ProfileBlogComment) {
        if (blogParent.length > 1) {
          if (getCommentsStatus === PENDING) {
            dispatch(getNotificationMessage({
              type: REJECTED,
              message: 'Please finish loading first before returning to previous blog post or comment',
              isEditProfile: true
            }))
          } else {
            const parentData = blogParent[blogParent.length - 1]

            dispatch(setComments([]))
            dispatch(getComments({
              type: parentData.parent_type,
              ...(parentData.parent_type === 'post' ? { post_id: parentData.post_id } : { comment_id: parentData.parent_comment_id })
            }))

            dispatch(setBlogParent(blogParent.slice(0, -1)))
          }
        } else {
          dispatch(setUserSectionPage(PROFILE_BLOG))
        }
      } else {
        dispatch(setUserSectionPage(MY_PROFILES))
      }
    } else if (modalTitle === VERIFY_PAGE.SignIn) {
      onClose()
    } else {
      dispatch(setAuthPage(SIGN_IN))
    }
  }

  const handleSettingButton = () => {
    setIsClickedOutside(false)

    if (modalTitle === VERIFY_PAGE.EditProfile && isProfileChanged === true) {
      setIsDiscard(true)
      setIsSetting(true)
    } else {
      dispatch(setUserSectionPage(SETTINGS))
    }
  }

  useEffect(() => {
    if (modalTitle === VERIFY_PAGE.EditProfile) {
      setIsClickedOutside(true)
    }
  }, [modalTitle])

  useEffect(() => {
    if (getUrlParams('token', 'email')[0] && getUrlParams('token', 'email')[1]) {
      onOpen()
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem('id') && !isAuth()) {
      dispatch(getNotificationMessage({
        type: REJECTED,
        message: 'Please log-in to view this marker.',
        isEditProfile: true
      }))
    }
  }, [])

  useEffect(() => {
    navigate('/')
    dispatch(handleOpenModal({ isOpen, section: MODAL_SECTION.LeftSection }))
  }, [isOpen])

  useEffect(() => {
    onClose()
  }, [isCloseModal])

  useEffect(() => {
    if (closeTheModal) {
      setCloseTheModal(false)
      onClose()
    }
  }, [closeTheModal])

  const getRandomDecimalWithPrecision = (min, max, precision) => {
    const factor = 10 ** precision
    min *= factor
    max *= factor
    return (Math.floor(Math.random() * (max - min + 1)) + min) / factor
  }

  // User Live Loc Update
  useEffect(() => {
    const activeID = activeProfile?.id
    const activeLocType = activeProfile?.location_type
    const activeCoordinates = activeProfile?.coordinates
    let addRadiusLat = 0
    let addRadiusLng = 0
    if (
      (
        activeID
        && (
          activeLocType?.[LOCATION_TYPE.LiveLocation] === 0
          || activeLocType?.[LOCATION_TYPE.LiveApproximate] === 1
        )
      )
      && Number.isNaN(xmppLiveLocation?.lat) === false
    ) {
      // live location
      if (activeLocType?.[LOCATION_TYPE.LiveLocation] === 0) {
        setTimeout(() => {
          if (
            (activeCoordinates?.lat !== xmppLiveLocation?.lat
            && activeCoordinates?.lng !== xmppLiveLocation?.lng)
            && localStorage.getItem('id') === null
          ) {
            if (xmppLiveLocation?.lat !== null) {
              const lat1 = L.latLng(xmppLiveLocation)
              const lat2 = L.latLng(activeCoordinates)
              const distance = lat2.distanceTo(lat1)
              // check whether the distance between the markers is 10m or more
              if (distance >= 10) {
                dispatch(setLiveLocData({ id: activeID, coordinates: xmppLiveLocation }))
                dispatch(getUserProfile())
              }
            }
          }
          setUpdateReset(!updateReset)
        }, 60000)
        // live approximate
      } else if (activeLocType?.[LOCATION_TYPE.LiveApproximate] === 1) {
        addRadiusLat = getRandomDecimalWithPrecision(-0.001, 0.001, 4)
        addRadiusLng = getRandomDecimalWithPrecision(-0.001, 0.001, 4)

        let ApproxCoord = {
          lat: Number((parseFloat(xmppLiveLocation?.lat) + addRadiusLat).toFixed(6)),
          lng: Number((parseFloat(xmppLiveLocation?.lng) + addRadiusLng).toFixed(6))
        }

        setTimeout(() => {
          if (
            (activeCoordinates?.lat !== xmppLiveLocation?.lat
            && activeCoordinates?.lng !== xmppLiveLocation?.lng)
            && localStorage.getItem('id') === null
          ) {
            if (xmppLiveLocation?.lat !== null) {
              const lat1 = L.latLng(xmppLiveLocation)
              const lat2 = L.latLng(activeCoordinates)
              const distance = lat2.distanceTo(lat1)
              // check whether the distance between the markers is 10m or more
              if (distance >= 10) {
                dispatch(setLiveLocData({ id: activeID, coordinates: ApproxCoord }))
                dispatch(getUserProfile())
              }
            }
          }
          setUpdateReset(!updateReset)
        }, 60000)
      }
    } else {
      setTimeout(() => setUpdateReset(!updateReset), 5000)
    }
  }, [updateReset])

  // Live Update Interval
  useEffect(() => {
    let currentDate = new Date().toLocaleString('en-US', { timeZone: 'UTC', hour12: false })
    if (liveMarkerExp) {
      let liveMarkerDate = new Date(liveMarkerExp).toLocaleString('en-US', { hour12: false })
      let duration = new Date(liveMarkerDate).getTime() - new Date(currentDate).getTime()
      let northeast = JSON.parse(localStorage.getItem('northeast'))
      let southwest = JSON.parse(localStorage.getItem('southwest'))

      setTimeout(() => {
        // console.log(currentDate >= liveMarkerDate)
        // console.log(duration / 1000 + ' seconds')
        if (
          currentDate >= liveMarkerDate
          && localStorage.getItem('id') === null
        ) {
          dispatch(liveMarkerUpdate({
            isAuth: isAuth(),
            northeast,
            southwest
          }))

          // Testing
          // console.log('Live is working')
          // console.log(currentDate + '')
          // console.log('Live Marker Expiration: ' + liveMarkerDate)
        }
        setLiveReset(!liveReset)
      }, 60000)
    } else {
      setTimeout(() => setLiveReset(!liveReset), 5000)
    }
  }, [liveReset])

  // Static Update Interval
  useEffect(() => {
    let currentDate = new Date().toLocaleString('en-US', { timeZone: 'UTC', hour12: false })
    if (staticMarkerExp) {
      let staticMarkerDate = new Date(staticMarkerExp).toLocaleString('en-US', { hour12: false })
      let duration = new Date(staticMarkerDate).getTime() - new Date(currentDate).getTime()
      let northeast = JSON.parse(localStorage.getItem('northeast'))
      let southwest = JSON.parse(localStorage.getItem('southwest'))

      setTimeout(() => {
        // console.log(currentDate >= staticMarkerDate)
        // console.log(duration / 1000 + ' seconds')
        if (
          currentDate >= staticMarkerDate
          && localStorage.getItem('id') === null
        ) {
          dispatch(staticMarkerUpdate({
            isAuth: isAuth(),
            northeast,
            southwest
          }))
          // Testing
          // console.log('Static is working')
          // console.log(currentDate + '')
          // console.log('Static Marker Expiration: ' + staticMarkerDate)
        }
        setStaticReset(!staticReset)
      }, 60000)
    } else {
      setTimeout(() => setStaticReset(!staticReset), 5000)
    }
  }, [staticReset])

  useEffect(() => {
    if (isProfileSwitched === true) {
      clearInterval(intervalRef.current)
      dispatch(setIsProfileSwitched(false))
    }
  }, [isProfileSwitched])

  const closingCondition = () => {
    if (closeCropModal && closeResetProfileModal) {
      if (modalTitle === VERIFY_PAGE.EditProfile && isSocialMediaSelectOpen === false) {
        if (isProfileChanged === true && isLocationOpen === false) {
          if (!isIOS()) {
            setIsCloseable(false)
          }
          setIsDiscard(true)
        } else if (
          isLocationOpen === true
          && (isProfileChanged === true || isProfileChanged === false)
        ) {
          if (!isIOS()) {
            setIsCloseable(false)
          }
          setIsDiscard(false)
        } else {
          onClose()
        }
      } else if (modalTitle === VERIFY_PAGE.EditProfile && isSocialMediaSelectOpen === true) {
        if (isProfileChanged === true) {
          dispatch(setUserSectionPage(EDIT_PROFILE))
          setIsCloseable(false)
          dispatch(setSocMedDisplay(false))
          dispatch(setSocMedDisplayStatus('none'))
        } else {
          dispatch(setUserSectionPage(EDIT_PROFILE))
          setIsDiscard(false)
          dispatch(setSocMedDisplay(false))
          dispatch(setSocMedDisplayStatus('none'))
        }
      } else if (modalTitle === VERIFY_PAGE.Settings) {
        if (isProfileDeleted === true) {
          setIsCloseable(false)
        } else {
          setIsCloseable(true)
          onClose()
        }
      } else {
        setIsDiscard(false)
        dispatch(setSocMedDisplay(false))
        dispatch(setSocMedDisplayStatus('none'))
        onClose()
      }
    }
  }

  // For Clicking outside the header
  useOutsideClick({
    ref: drawerRef,
    handler: () => {
      closingCondition()
    }
  })

  return (
    <>
      <Stack
        display="flex"
        justifyContent="center"
        paddingBlock="9px"
        spacing={{
          base: '10px',
          sm: '10px',
          md: '15px',
          lg: '60px'
        }}
        direction="row"
        alignItems="center"
        background={COLORS.gold}
        className="header"
      >
        {isAuth() ? (
          <Image
            boxSize="32px"
            cursor="pointer"
            objectFit="cover"
            src={usePhoto || ImagesPng.UserImage}
            alt="Logo"
            borderRadius="full"
            onClick={() => {
              document.querySelector('div.leaflet-container').click()
              dispatch(setUserSectionPage(MY_PROFILES))
              onOpen()
            }}
            onLoad={() => {
              if (id !== null && !isAuth()) {
                onOpen()
              }
            }}
            ref={btnRef}
            className="profile-icon"
          />
        ) : (
          <Image
            as={motion.img}
            // initial={{ scale: 1 }}
            animate={{
              scale: [1, 1.1, 1],
              opacity: [1, 0.8, 1],
              transition: {
                repeat: Infinity,
                duration: 1
              }
            }}
            boxSize="32px"
            cursor="pointer"
            objectFit="cover"
            src={usePhoto || ImagesPng.UserImage}
            alt="Logo"
            borderRadius="full"
            onClick={() => {
              document.querySelector('div.leaflet-container').click()
              onOpen()
            }}
            onLoad={() => {
              if (id !== null && !isAuth()) {
                onOpen()
              }
            }}
            ref={btnRef}
            className="profile-icon"
          />
        )}

        <SearchContainer />

        <Messenger />
      </Stack>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="sm"
        closeOnOverlayClick={isCloseable}
        closeOnEsc={false}
        onEsc={() => handleCloseButton()}
      >
        <DrawerContent
          sx={{
            width: '95% !important'
          }}
          borderRadius="10px"
          overflow="hidden"
          marginBlock="60px"
          marginInline="10px"
          maxWidth="450px"
          ref={drawerRef}
        >
          <Stack
            // spacing="43px"
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            p="0px 0"
            background={COLORS.goldGradientLeftToRight}
            zIndex={5}
            height="32px"
            overflow="hidden"
            onTouchStart={(e) => {
              touchStart = e?.targetTouches[0].clientX
            }}
            onTouchMove={(e) => {
              touchEnd = e?.targetTouches[0].clientX
            }}
            onTouchEnd={(e) => {
              if (touchStart - touchEnd > 45) {
                closingCondition()
              }
            }}
          >
            <IconButton
              name="profile-pane-back-btn"
              _hover={{ background: COLORS.gold_hover }}
              background="unset"
              icon={<ArrowIcon />}
              onClick={handleCloseButton}
              outline="none"
              boxShadow="none"
              css={{
                ':focus': {
                  outline: 'none',
                  boxShadow: 'none'
                }
              }}
            />

            <Text
              whiteSpace="nowrap"
              fontSize="20px"
              sx={{
                marginLeft: '105px !important',
                '@media (max-width: 480px)': {
                  marginLeft: '65px !important'
                }
              }}
              fontWeight={600}
            >
              {
                modalTitle === VERIFY_PAGE.EditProfile && isSocialMediaSelectOpen
                  ? 'Choose Platform' : ''
              }
            </Text>

            <Box width="95%" />

            {user && !isSocialMediaSelectOpen ? (
              <IconButton
                _hover={{ background: COLORS.gold_hover }}
                background="unset"
                icon={<SettingsIcon />}
                onClick={handleSettingButton}
                outline="none"
                boxShadow="none"
                css={{
                  ':focus': {
                    outline: 'none',
                    boxShadow: 'none'
                  }
                }}
              />
            ) : null}
          </Stack>

          <DrawerBody
            sx={{
              overflowX: 'hidden',
              '::MsOverflowStyle': 'none',
              scrollbarWidth: 'none',
              '::-webkit-scrollbar': {
                display: 'none'
              }
            }}
            p="24px 65px"
          >
            {withAuthentication(UserSection)}
          </DrawerBody>
          <DiscardChanges
            isDiscard={isDiscard}
            setIsDiscard={setIsDiscard}
            setIsCloseable={setIsCloseable}
            isProfileActive={isProfileActive}
            isProfileIncomplete={isProfileIncomplete}
            isSetting={isSetting}
            setIsSetting={setIsSetting}
            isClickedOutside={isClickedOutside}
            setIsClickedOutside={setIsClickedOutside}
            setCloseTheModal={setCloseTheModal}
            closeCropModal={closeCropModal}
          />
          <CropPicture
            isPictureUploaded={isPictureUploaded}
            setCloseCropModal={setCloseCropModal}
            cropFor={cropFor}
          />
        </DrawerContent>
      </Drawer>
    </>
  )
}
