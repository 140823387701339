import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Text, Stack } from '@chakra-ui/react'
import { handleModalTitle } from 'store'
import { UserInformation } from 'components/UserSection/ProfileBlog/UserInformation'
import { CreateComment } from 'components/UserSection/ProfileBlog/Comments/CreateComment'
import { CommentsForPost } from 'components/UserSection/ProfileBlog/Comments/CommentsForPost'
import { VERIFY_PAGE } from 'utils/constants'
import { COLORS } from 'utils/styles'

export const Comments = ({
  selectedUser,
  comments,
  getCommentsStatus,
  postCommentsStatus,
  putCommentsStatus,
  blogParent,
  commentsOption
}) => {
  const dispatch = useDispatch()
  const [editCommentData, setEditCommentData] = useState({})

  const {
    full_name,
    profile_picture,
    banner
  } = selectedUser

  useEffect(() => {
    dispatch(handleModalTitle(VERIFY_PAGE.ProfileBlogComment))
  }, [])

  return (
    <Stack direction="column">
      <UserInformation
        profilePicture={profile_picture}
        fullName={full_name}
        coverPicture={banner}
      />

      <Stack
        display="flex"
        justifyContent="center"
        direction="row"
        marginTop="10px"
        marginBottom="-5px"
        width="calc(100% + 112px)"
        marginLeft="-56px"
      >
        <Text
          background={COLORS.lightGoldGradientLeftToRight}
          padding="2px 5%"
          fontSize="19px"
          width="85%"
          borderRadius="5px"
          align="center"
          letterSpacing=".15px"
          fontWeight="400"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {blogParent[blogParent.length - 1].title ?? blogParent[blogParent.length - 1].body}
        </Text>
      </Stack>

      <CreateComment
        selectedUser={selectedUser}
        blogParent={blogParent}
        getCommentsStatus={getCommentsStatus}
        type={commentsOption.type}
        postCommentsStatus={postCommentsStatus}
        putCommentsStatus={putCommentsStatus}
        editCommentData={editCommentData}
        setEditCommentData={setEditCommentData}
      />

      <CommentsForPost
        selectedUser={selectedUser}
        comments={comments}
        getCommentsStatus={getCommentsStatus}
        setEditCommentData={setEditCommentData}
        commentsOption={commentsOption}
        blogParent={blogParent}
      />
    </Stack>
  )
}
