import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Input,
  Stack,
  Text
} from '@chakra-ui/react'
import {
  usersSelectors
} from 'store'
import { COLORS } from 'utils/styles'

export const Affinities = ({
  activeBlogPref,
  addPreference
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [affinitiesInput, setAffinitiesInput] = useState('')

  const affinitiesListRef = useRef(null)
  const inputRef = useRef(null)

  const {
    searchBar
  } = useSelector(usersSelectors)
  const affinityList = searchBar.affinities

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        affinitiesListRef.current
        && !affinitiesListRef.current.contains(e.target)
        && !inputRef.current.contains(e.target)
      ) {
        setIsPopupOpen(false)
      }
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#once
    // If {once: true}, the listener would be automatically removed when invoked.
    document.body.addEventListener('mousedown', handleOutsideClick, { once: true })
  }, [isPopupOpen])

  const filterAffinityList = (affinities = affinityList) => affinities.map((item) => (
    <Box
      onClick={() => addPreference(item.id)}
      p="3px 6px"
      cursor="pointer"
      borderRadius="5px"
      bg={COLORS.gold}
      width="max-content"
      alignItems="center"
      name={item.title}
      fontSize="15px"
    >
      {item.title}
    </Box>
  ))

  const [filteredAffinityList, setFilteredAffinityList] = useState(filterAffinityList())

  useEffect(() => {
    let affinityListTemp = affinityList
    const tempAffinitiesInput = affinitiesInput.toLowerCase()

    if (affinitiesInput !== '') {
      affinityListTemp = affinityListTemp.filter((item) => {
        const title = item.title.toLowerCase()

        if (
          !activeBlogPref.includes(item.title)
          && tempAffinitiesInput === title.substring(0, tempAffinitiesInput.length)
        ) {
          return true
        }

        return false
      })
    }

    setFilteredAffinityList(filterAffinityList(affinityListTemp))
  }, [affinitiesInput])

  return (
    <Stack direction="column" spacing="10px" marginTop="30px">
      <Box
        display="flex"
        justifyContent="center"
      >
        <Input
          ref={inputRef}
          placeholder="Search Affinities"
          value={affinitiesInput}
          onChange={(e) => setAffinitiesInput(e.target.value)}
          onFocus={() => setIsPopupOpen(true)}
          name="profile_affinities"
          width="90%"
          height="35px"
          fontSize="15px"
          border="solid 1px black"
          background={COLORS.lighterGray}
          style={{
            '::placeholder': {
              color: 'black'
            }
          }}
        />
      </Box>

      {isPopupOpen && (
        <Box
          display="flex"
          justifyContent="center"
        >
          <Stack
            ref={affinitiesListRef}
            gap="10px"
            spacing="0px"
            direction="row"
            flexWrap="wrap"
            data-name="affinityList"
            maxHeight="14em"
            width="90%"
            overflowY="scroll"
            background={COLORS.lighterGray}
            padding="5px"
            borderRadius="0 0 5px 5px"
            marginTop="-10px"
          >
            {
              filteredAffinityList.length
                ? filteredAffinityList
                : (
                  <Text paddingLeft="12px">Search result not found or already chosen</Text>
                )
            }
          </Stack>
        </Box>
      )}
    </Stack>
  )
}
