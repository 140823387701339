import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box, Card, CardBody, CardHeader,
  Stack
} from '@chakra-ui/react'
import { authSelectors } from 'store'
import { NOTIFICATIONS_TITLE } from 'utils/constants'
import { createValidFormatData } from 'utils/helpers'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'
import { GuestContentItem } from './GuestContentItem'
import { GuestProfile } from './GuestProfile'

export const GuestNotification = () => {
  const {
    guestNotification
  } = useSelector(authSelectors)
  const cardRef = useRef()

  const notificationsTitle = (title) => {
    switch (title) {
    case NOTIFICATIONS_TITLE.Connector:
      return 'Connector'
    case NOTIFICATIONS_TITLE.Proximity:
      return 'Proximity'
    case NOTIFICATIONS_TITLE.NewUsers:
      return 'New Users'
    case NOTIFICATIONS_TITLE.Concerts:
      return 'Concerts and Events'
    case NOTIFICATIONS_TITLE.CommunityBlogs:
      return 'Community Blogs'
    case NOTIFICATIONS_TITLE.Advertisers:
      return 'Advertisers'
    default:
      return ''
    }
  }

  return (
    <Stack
      margin="0px"
      overflowY="auto"
      overflowX="hidden"
      height="100%"
      gap="0 !important"
      className="notif-container"
    >
      <GuestProfile isBlocked={false} />
      {createValidFormatData(guestNotification).map((item) => {
        const title = notificationsTitle(item.id)

        if (title === '') {
          return ''
        }

        const isViewedNotification = item?.value?.filter(
          (n) => n?.is_viewed === 0
        ).length

        return (
          <Card key={uuid()} ref={cardRef} boxShadow="none" marginTop="0px !important" background="#c4c3c7">
            <CardHeader display="flex" alignItems="center" padding="0 25px" background="#feeb88">
              <Box textAlign="left" paddingRight="10px">
                {title}
              </Box>
              {isViewedNotification > 0 && (
                <Stack direction="row" alignItems="center">
                  <Box
                    textAlign="center"
                    background={COLORS.gold}
                    width="17px"
                    height="17px"
                    borderRadius="full"
                    position="relative"
                  >
                    <Box
                      fontSize="12px"
                      fontWeight={500}
                      transform="translate(-50%, -50%)"
                      top="50%"
                      left="50%"
                      position="absolute"
                    >
                      {isViewedNotification > 99 ? '99+' : isViewedNotification}
                    </Box>
                  </Box>
                </Stack>
              )}
            </CardHeader>

            <CardBody padding="5px" minHeight="140px">
              <Box
                sx={{
                  overflowX: 'auto',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px'
                }}
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                    height: '10px',
                    display: 'block'
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '24px',
                    background: 'rgba(255, 215, 0, 1)'
                  }
                }}
              >
                <GuestContentItem items={item?.value} title={item.id} />
              </Box>
            </CardBody>
          </Card>
        )
      })}
      <GuestProfile isBlocked />
    </Stack>
  )
}
