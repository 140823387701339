import React from 'react'

export const RepostIcon = () => (
  <svg
    fill="#000000"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    transform="rotate(180)"
    width="20"
    height="20"
    cursor="pointer"
    style={{ rotate: '90deg' }}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
    <g id="SVGRepo_iconCarrier">
      <path
        d="M6,14V9A6,6,0,0,1,16.89,5.54"
        style={{
          fill: 'none',
          stroke: '#000000',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 2
        }}
      />
      <path
        d="M18,10v5A6,6,0,0,1,7.11,18.46"
        style={{
          fill: 'none',
          stroke: '#000000',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 2
        }}
      />
      <polyline
        points="8 12 6 14 4 12"
        style={{
          fill: 'none',
          stroke: '#000000',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 2
        }}
      />
      <polyline
        points="16 12 18 10 20 12"
        style={{
          fill: 'none',
          stroke: '#000000',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 2
        }}
      />
    </g>
  </svg>
)
