import { createAsyncThunk } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import {
  getNotificationMessage, setMarkerUpdate, forceLogout, setIsUpdatingAvatar
} from 'store'
import L from 'leaflet'
import { getParsedData } from 'utils/helpers'
import { REJECTED } from 'utils/constants'
import { Buffer } from 'buffer'
import { openDB, wrap, unwrap } from 'idb'
import { markersApi } from './api'

// For separate image requests
export const getMarkerAvatars = createAsyncThunk(
  'markers/staticMarkerUpdate',
  async (body, { rejectWithValue }) => {
    const {
      isAuth, type, id
    } = body
    try {
      const response = await markersApi.getMarkerAvatars({ isAuth, type, id })

      let data = {
        markerID: id,
        imageBase64: 'data:image/png;base64,' + Buffer.from(response.data, 'binary').toString('base64')
      }

      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

// for grouped marker avatar request
export const updateMarkerAvatars = createAsyncThunk(
  'markers/updateMarkerAvatars',
  async (body, { rejectWithValue, dispatch }) => {
    const { isAuth, markerId } = body
    try {
      const response = await markersApi.updateMarkerAvatars({ isAuth, markerid: markerId })
      dispatch(setIsUpdatingAvatar(false))

      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getMarkers = createAsyncThunk(
  'markers/getMarkers',
  async (body, { rejectWithValue, dispatch }) => {
    const {
      filter = [], isAuth
    } = body

    let northEast = L.latLng(JSON.parse(localStorage.getItem('northeast')))
    let southWest = L.latLng(JSON.parse(localStorage.getItem('southwest')))
    let markerId = []

    try {
      const response = await markersApi.getMarkers({ filter, isAuth })

      if (isAuth) {
        try {
          response.data.data = getParsedData(response.data.data)
        } catch (err) {
          // Nothing will happen here
        }
      }

      let stringCoordCount = 0
      response.data.data.forEach((item) => {
        if (isAuth
            && (item.coordinates[0] <= northEast.lat
            && item.coordinates[0] >= southWest.lat
            && item.coordinates[1] <= northEast.lng
            && item.coordinates[1] >= southWest.lng
            )
        ) {
          if (item?.is_friends === 'accepted') {
            markerId.push(item?.id)
          }
        }
        if (typeof item.coordinates[0] === 'string' || typeof item.coordinates[1] === 'string') {
          stringCoordCount += 1
        }
      })

      if (stringCoordCount > 0) {
        dispatch(getNotificationMessage({
          type: REJECTED,
          message: 'One of the marker coordinates value has wrong format.',
          isEditProfile: false
        }))
      }

      if (isAuth && markerId.length > 0 && localStorage.getItem('id') === null) {
        await dispatch(updateMarkerAvatars({ isAuth: true, markerId }))
      }
      await dispatch(setMarkerUpdate(true))
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

// For getting friends markers
export const getFriendMarkers = createAsyncThunk(
  'markers/getFriendMarkers',
  async (body, { rejectWithValue, dispatch }) => {
    const { isAuth } = body
    try {
      const response = await markersApi.getFriendMarkers({ isAuth })
      await dispatch(setMarkerUpdate(true))
      return response.data.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

// Live Marker Update
export const liveMarkerUpdate = createAsyncThunk(
  'markers/liveMarkerUpdate',
  async (body, { rejectWithValue, dispatch }) => {
    const { isAuth, northeast, southwest } = body

    try {
      const response = await markersApi.liveMarkerUpdate({ isAuth, northeast, southwest })

      if (isAuth) {
        try {
          response.data.data = getParsedData(response.data.data)
        } catch (err) {
          //
        }
      }

      await dispatch(setMarkerUpdate(true))
      return response.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))

      return rejectWithValue(error.message)
    }
  }
)

// Static Marker Update
export const staticMarkerUpdate = createAsyncThunk(
  'markers/staticMarkerUpdate',
  async (body, { rejectWithValue, dispatch }) => {
    const { isAuth, northeast, southwest } = body
    try {
      const response = await markersApi.staticMarkerUpdate({ isAuth, northeast, southwest })

      if (isAuth) {
        try {
          response.data.data = getParsedData(response.data.data)
        } catch (err) {
          //
        }
      }

      await dispatch(setMarkerUpdate(true))
      return response.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))

      return rejectWithValue(error.message)
    }
  }
)
