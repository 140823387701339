import { useRef } from 'react'
import { Marker } from 'react-leaflet'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { createMarkerColor } from 'utils/helpers'
import { v4 as uuid } from 'uuid'

export const CopyMarkerItem = ({ item, mapRef, avatar }) => {
  const markerRef = useRef()
  const [searchParams, setSearchParams] = useSearchParams()

  const validDate = dayjs(item?.last_accessed)
  const iconMarker = createMarkerColor(validDate, avatar)

  return (
    <Marker
      zIndexOffset={-99999}
      eventHandlers={{
        click: (e) => {
          markerRef.current.closePopup()

          const trueLng = e.latlng.lng > 180 ? e.latlng.lng - 360 : e.latlng.lng + 360

          mapRef.current.setView([
            e.latlng.lat,
            trueLng
          ], localStorage.getItem('zoom'), { animate: true })

          setTimeout(() => {
            setSearchParams({ id: item.id })
          }, 300)
        }
      }}
      key={uuid()}
      icon={iconMarker}
      position={item.coordinates}
      ref={markerRef}
    />
  )
}
