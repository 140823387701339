import axios from 'axios'
import { API } from 'configs/api'

export const usersApi = {
  getUser: () => axios.post(
    API.userInfo,
    {},
    { token: true }
  ),
  deleteUser: () => axios.post(
    API.userDestroy,
    {},
    { token: true }
  ),
  getUserProfiles: () => axios.post(
    API.profile,
    {},
    { token: true }
  ),
  getChatCredentials: () => axios.post(
    API.chat,
    {},
    { token: true }
  ),
  selectProfile: (profileId) => axios.post(
    API.selectProfile,
    { profileId },
    { token: true }
  ),
  searchAffinities: (search) => axios.post(
    API.affinity,
    { limit: 15, search },
    { token: true }
  ),
  updateProfile: (body) => axios.post(
    API.updateProfile,
    body,
    { token: true }
  ),
  updateSwitcher: (body) => axios.post(
    API.profile + API.connectionsVisibility,
    body,
    { token: true }
  ),
  updateProfileImage: (body, id) => axios.post(
    `${API.profile}/${id + API.picture}`,
    body,
    { token: true, formData: true }
  ),
  updateBanner: (body, id) => axios.post(
    `${API.profile}/${id + API.banner}`,
    body,
    { token: true, formData: true }
  ),
  getSearchParams: (search) => axios.post(
    API.searchBar,
    { search },
    { token: true }
  ),
  getProfileItem: (profileId) => axios.post(
    API.profileInfo,
    { profileId },
    { token: true }
  ),
  deleteProfile: (profileId) => axios.post(
    API.profile + API.clear,
    { profileId },
    { token: true }
  ),
  updateSettings: (body) => axios.post(
    API.userUpdate,
    body,
    { token: true }
  ),
  getFriendsNotifications: () => axios.post(
    API.profile + API.getFriendsNotifications,
    {},
    { token: true }
  ),
  addFriend: (receiver_profile_id) => axios.post(
    API.profile + API.sendFriendRequest,
    { receiver_profile_id },
    { token: true }
  ),
  deleteFriend: (friendId) => axios.post(
    API.profile + API.friends + API.unfriend,
    { friendId },
    { token: true }
  ),
  acceptFriend: (sender_id) => axios.post(
    API.profile + API.friends + API.accept,
    { sender_id },
    { token: true }
  ),
  denyFriend: (senderId) => axios.post(
    API.profile + API.friends + API.deny,
    { senderId },
    { token: true }
  ),
  getAdminNotifications: () => axios.post(
    API.notifications,
    {},
    { token: true }
  ),
  postMakeNotificationSeen: (notificationId) => axios.post(
    API.notificationsSeen,
    { notificationId },
    { token: true }
  ),
  searchSocialLinks: (search) => axios.post(
    API.socialLinks,
    { limit: 15, search },
    { token: true }
  ),
  getSocialLinksCategories: () => axios.post(
    API.socialLinksCategories,
    {},
    { token: true }
  ),
  getFrequency: () => axios.post(
    API.frequency,
    {},
    { token: true }
  ),
  setLiveLocData: (id, coordinates) => axios.post(
    API.liveLocData,
    { id, coordinates },
    { token: true }
  ),
  getPingTest: () => axios.post(
    API.pingTest,
    {},
    { token: true }
  ),
  getFileTest: () => axios.post(
    API.fileTest,
    {},
    { token: true }
  ),
  setDataTest: (personal_quote) => axios.post(
    API.dataTest,
    { personalQuote: personal_quote },
    { token: true }
  ),
  archiveRequest: (id) => axios.post(
    API.archive,
    { friend_request_id: id },
    { token: true }
  ),
  getBlockedRequest: (id) => axios.post(
    API.blockedUser,
    { id },
    { token: true }
  ),
  getPosts: (id) => axios.post(
    API.getPosts,
    id,
    { token: true }
  ),
  postPosts: (body) => axios.post(
    API.postPosts,
    body,
    { token: true }
  ),
  addImagePosts: (image) => axios.post(
    API.addImagePosts,
    image,
    { token: true, formData: true }
  ),
  getImagePosts: (body) => axios.post(
    API.getImagePosts,
    body,
    { token: true, responseType: 'arraybuffer' }
  ),
  putPosts: (body) => axios.post(
    API.putPosts,
    body,
    { token: true }
  ),
  likePost: (id) => axios.post(
    API.likePost,
    id,
    { token: true }
  ),
  getBlogPreference: () => axios.post(
    API.getBlogPreference,
    {},
    { token: true }
  ),
  blogPreferenceAllContacts: () => axios.post(
    API.blogPreferenceAllContacts,
    {},
    { token: true }
  ),
  addBlogPreference: (id) => axios.post(
    API.addBlogPreference,
    id,
    { token: true }
  ),
  removeBlogPreference: (id) => axios.post(
    API.removeBlogPreference,
    id,
    { token: true }
  ),
  getComments: (body) => axios.post(
    API.getComments,
    body,
    { token: true }
  ),
  postComments: (body) => axios.post(
    API.postComments,
    body,
    { token: true }
  ),
  likeComment: (id) => axios.post(
    API.likeComment,
    id,
    { token: true }
  ),
  putComments: (body) => axios.post(
    API.putComments,
    body,
    { token: true }
  )
}
