import { createSlice } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import {
  emailVerify,
  forgotPassword,
  getGuestNotification,
  logOut,
  resetPassword,
  signIn,
  signUp
} from 'store'
import {
  IS_VERIFY_PAGE,
  PASSWORD_VERIFY_TEXT,
  PENDING,
  REJECTED,
  SIGN_UP_VERIFY_TEXT,
  SUCCEEDED
} from 'utils/constants'

const initialState = {
  accessToken: null,
  user: null,
  pageName: '',
  verifyPageText: '',
  modalTitle: '',
  authPage: '',
  isSignIn: false,
  isAlreadyVerified: false,
  guestNotification: [],
  signInStatus: PENDING | SUCCEEDED | REJECTED,
  signUpStatus: PENDING | SUCCEEDED | REJECTED,
  emailVerifyStatus: PENDING | SUCCEEDED | REJECTED,
  forgotPasswordStatus: PENDING | SUCCEEDED | REJECTED,
  resetPasswordStatus: PENDING | SUCCEEDED | REJECTED,
  logOutStatus: PENDING | SUCCEEDED | REJECTED,
  guestNotificationStatus: PENDING | SUCCEEDED | REJECTED
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleChangePage: (state) => {
      state.pageName = ''
    },
    handleModalTitle: (state, action) => {
      state.modalTitle = action.payload
    },
    setAuthPage: (state, action) => {
      state.authPage = action.payload
    },
    setIsSignIn: (state, action) => {
      state.isSignIn = action.payload
    }
  },
  extraReducers: {
    // SIGN IN
    [signIn.pending]: (state) => {
      state.signInStatus = PENDING
    },
    [signIn.fulfilled]: (state, action) => {
      state.signInStatus = SUCCEEDED
      state.accessToken = action.payload
    },
    [signIn.rejected]: (state) => {
      state.signInStatus = REJECTED
    },
    // SIGN UP
    [signUp.pending]: (state) => {
      state.signUpStatus = PENDING
    },
    [signUp.fulfilled]: (state) => {
      state.signUpStatus = SUCCEEDED
      state.pageName = IS_VERIFY_PAGE
      state.verifyPageText = SIGN_UP_VERIFY_TEXT
    },
    [signUp.rejected]: (state) => {
      state.signUpStatus = REJECTED
    },
    // Email Verify
    [emailVerify.pending]: (state) => {
      state.emailVerifyStatus = PENDING
    },
    [emailVerify.fulfilled]: (state) => {
      state.emailVerifyStatus = SUCCEEDED
    },
    [emailVerify.rejected]: (state) => {
      state.emailVerifyStatus = REJECTED
      state.isAlreadyVerified = true
    },
    // Forgot Password
    [forgotPassword.pending]: (state) => {
      state.forgotPasswordStatus = PENDING
    },
    [forgotPassword.fulfilled]: (state) => {
      state.forgotPasswordStatus = SUCCEEDED
      state.pageName = IS_VERIFY_PAGE
      state.verifyPageText = PASSWORD_VERIFY_TEXT
    },
    [forgotPassword.rejected]: (state) => {
      state.forgotPasswordStatus = REJECTED
    },
    // Reset Password
    [resetPassword.pending]: (state) => {
      state.resetPasswordStatus = PENDING
    },
    [resetPassword.fulfilled]: (state) => {
      state.resetPasswordStatus = SUCCEEDED
    },
    [resetPassword.rejected]: (state) => {
      state.resetPasswordStatus = REJECTED
    },
    // Log Out
    [logOut.pending]: (state) => {
      state.logOutStatus = PENDING
    },
    [logOut.fulfilled]: (state) => {
      state.logOutStatus = SUCCEEDED
    },
    [logOut.rejected]: (state) => {
      state.logOutStatus = REJECTED
    },
    // Guest Notification
    [getGuestNotification.pending]: (state) => {
      state.guestNotificationStatus = PENDING
    },
    [getGuestNotification.fulfilled]: (state, action) => {
      state.guestNotificationStatus = SUCCEEDED
      state.guestNotification = action.payload
    },
    [getGuestNotification.rejected]: (state) => {
      state.guestNotificationStatus = REJECTED
    }
  }
})

export const {
  handleChangePage, handleModalTitle, setAuthPage, setIsSignIn
} = auth.actions

export default auth.reducer
